import React, { useState, useEffect, useRef } from 'react';

const VoiceRecorder = () => {
    const [isRecording, setIsRecording] = useState(false);
    const [audioChunks, setAudioChunks] = useState([]);
    const audioContextRef: any = useRef(null);
    const analyserRef: any = useRef(null);
    const mediaRecorderRef: any = useRef(null);
    const [volume, setVolume] = useState(0);

    useEffect(() => {
        if (isRecording) {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                    //@ts-ignore
                    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
                    const source = audioContextRef.current.createMediaStreamSource(stream);
                    analyserRef.current = audioContextRef.current.createAnalyser();
                    source.connect(analyserRef.current);

                    mediaRecorderRef.current = new MediaRecorder(stream);
                    mediaRecorderRef.current.start();

                    mediaRecorderRef.current.ondataavailable = (event: any) => {
                        //@ts-ignore
                        setAudioChunks((prevChunks) => [...prevChunks, event.data]);
                    };

                    const dataArray = new Uint8Array(analyserRef.current.fftSize);

                    const draw = () => {
                        analyserRef.current.getByteTimeDomainData(dataArray);
                        let sum = 0;
                        for (let i = 0; i < dataArray.length; i++) {
                            const value = (dataArray[i] - 128) / 128;
                            sum += value * value;
                        }
                        setVolume(Math.sqrt(sum / dataArray.length) * 100);
                        requestAnimationFrame(draw);
                    };

                    draw();
                });
        } else {
            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.stop();
                mediaRecorderRef.current = null;
            }
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
        }
    }, [isRecording]);

    const startRecording = () => {
        setAudioChunks([]);
        setIsRecording(true);
    };

    const stopRecording = () => {
        setIsRecording(false);
    };

    const downloadRecording = () => {
        const blob = new Blob(audioChunks, { type: 'audio/wav' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'recording.wav';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <div>
            <button onClick={isRecording ? stopRecording : startRecording}>
                {isRecording ? 'Stop Recording' : 'Start Recording'}
            </button>
            {isRecording && (
                <div>
                    <p>Recording... Voice Balance: {volume.toFixed(2)}</p>
                    <div
                        style={{
                            width: `${volume}%`,
                            height: '10px',
                            backgroundColor: 'green',
                            marginTop: '10px'
                        }}
                    />
                </div>
            )}
            {!isRecording && audioChunks.length > 0 && (
                <button onClick={downloadRecording}>Download Recording</button>
            )}
        </div>
    );
};

export default VoiceRecorder;
