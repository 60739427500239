import { BtnLoader } from "components/widget"
import { useSendMessage } from "hooks"
import { useEffect, useRef, useState } from "react"
import { ChatStore } from "store"
import { closeWebSocketConnection, createWebSocketConnection, methodHandelSendMessage, methodHandelSendMessageSocket } from "utils"

export const ChatStepThreeForm = () => {
  const formRef: any = useRef()
  const [socket, setSocket] = useState<any>(null);
  const { clientChat, setClientChat } = ChatStore()
  // هوک زیر فقط برای ارتباط رست بدرد میخوره
  const { isLoading, mutate } = useSendMessage(clientChat, setClientChat, formRef, 'client')

  // فانکشن زیر هم فقط داخل رست کاربرد دارد
  const handelSendMessage = (e: any) => {
    e.preventDefault()
    let room = JSON.parse(String(localStorage.getItem('room_info')))
    methodHandelSendMessage(room, mutate, formRef, 'client')
  }

  const handelSendMessageSocket = (e: any) => {
    e.preventDefault()
    methodHandelSendMessageSocket(socket, formRef, clientChat, setClientChat , 'client')
  }

  useEffect(() => {
    createWebSocketConnection(clientChat, setClientChat, setSocket , 'client')
  }, [])

  useEffect(() => {
    closeWebSocketConnection(socket, setSocket)
  }, [socket]);


  return (
    <form ref={formRef} id="send-message-external-form" onSubmit={handelSendMessageSocket} className="w-full py-4  md:px-[20px] px-[10px] border-t border-hoory-75 flex items-center justify-between  bg-hoory-100">
      {/* <div className="w-[35px] h-[35px] rounded-md flex items-center justify-center bg-hoory-125 cursor-pointer">
        <CSvgAttch className="w-[25px] stroke-gray-500" />
      </div> */}
      <input name='message' placeholder="Type message" className="md:w-[calc(100%-75px)] w-[calc(100%-75px)] h-[35px] px-2 text-sm focus:outline-none border border-hoory-75 rounded-md bg-transparent" />
      {isLoading ?
        <button type="button" className="md:w-[100px] w-[70px] h-[35px] flex items-center justify-center text-sm bg-hoory-200 text-hoory-175 rounded-md relative"><BtnLoader /></button>
        :
        <button type="submit" className="md:w-[100px] w-[70px] h-[35px] flex items-center justify-center text-sm bg-hoory-200 text-hoory-175 rounded-md">Send</button>
      }
    </form>
  )
}